import React from 'react'
import LoginStore from './login.store'
import DocsStore from './doc.store'

export default class RootStore {
  constructor() {
    this.loginStore = new LoginStore(); 
    this.docsStore= new DocsStore();
  }

  loginStore() 
  {
      return this.loginStore;
  }

  docsStore() 
  {
    return this.docsStore; 
  }
  
}

const StoreContext = React.createContext(); 

export const StoreProvider = ({children, store}) => {
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    )
}

  /* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext)


/* HOC to inject store to any functional or class component */

export const withStore = (Component) => (props) => {
    return <Component {...props} store={useStore()} />;
  };