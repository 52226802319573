
import app from 'firebase/app'
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithCredential, GoogleAuthProvider } from "firebase/auth";
import {makeObservable, observable, action} from "mobx"

export default class LoginStore {
   subscriptions = []
    subscriptionLabel = 'No subscriptions exist'; 
    loggedIn = false; 
   navigate = null; 
  recaptcha = null; 
  loginButtonClickAction = null; 
  instance = null; 
  confirmedNotBot = false; 
  performFirebaseLogin = false; 
  emailAddress = null; 
  password = null; 
  errorMessage = null;

  constructor() {



  makeObservable(this, {
    subscriptionLabel: observable,
    subscriptions: observable,
    loggedIn: observable,
    confirmedNotBot: observable, 
    performFirebaseLogin: observable,
    emailAddress: observable, 
    password: observable,
    errorMessage: observable,
    setSubscriptionLabel: action.bound,
    setSubscriptions: action.bound,
    setLoggedIn: action.bound,
    onLogin: action.bound,
    setNavigate: action.bound,
    setConfirmedNotBot: action.bound,
    setPerformFirebaseLogin: action.bound,
    setEmail: action.bound,
    setPassword: action.bound,
    setErrorMessage: action.bound
    
  })

  
  }

  async onFormSubmit(recaptchaRef)
  {
    await recaptchaRef.current.reset();
     recaptchaRef.current.execute()
  }

  setErrorMessage(message)
  {
    this.errorMessage = message;
  }
  setEmail(email){
    this.emailAddress = email
  }
  setPassword(password){
    this.password = password
  }

  setConfirmedNotBot(confirmed)
  {
    this.confirmedNotBot = confirmed; 
  }
  setRecaptcha(recaptcha)
  {
    this.recaptcha = recaptcha;
  }
  setSubscriptionLabel(description)
  {
    this.subscriptionLabel = description;
  }

  setSubscriptions(inputSubscriptions)
  {
    this.subscriptions = inputSubscriptions;
  }

  setNavigate(nav)
  {
    this.navigate = nav; 
  }

  setPerformFirebaseLogin(perform)
  {
    this.performFirebaseLogin = perform
  }

  onRecaptchaInit(obj, loginButtonClick)
  {
      if (obj !== undefined)
      {
        obj.loginButtonClickAction = loginButtonClick;
      
      }
      
    }

    performLogin(obj)
    {

          if (obj.loginButtonClickAction !== null)
          {
            obj.loginButtonClickAction();
          }
      
        
    }

async firebaseLogin(email, password)
{
  this.setErrorMessage(null);

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in 
        const user = userCredential.user;
           await this.onLogin(user.getIdToken(), null);
        // ...
      })
      .catch((error) => {
        console.error(error.code)
        this.setErrorMessage("Invalid email and/or password")
      });
}

 async   onLogin(idToken, accessToken)
    {

        if (idToken !== undefined || accessToken !== undefined)//user !== undefined)
        {
           this.setLoggedIn(true); 
            console.info('logged in')
            const functions = getFunctions();
            const auth = getAuth()
            console.info('id token was ' + idToken)
            console.info('accessToken was ' + accessToken)
           const cred = GoogleAuthProvider.credential(idToken,accessToken)
           await signInWithCredential(auth,cred)
            
            const getMySubscriptions = httpsCallable(functions, 'getMySubscriptions');
            const returned = [];

            getMySubscriptions()
              .then((result) => {
                // Read result of the Cloud Function.
                /** @type {any} */
                const accountDetails = result.data.subscriptions;
                this.subscriptions = accountDetails; 
                accountDetails.forEach((item, idx) => {
                  
                  returned.push(`Your subscription to ${item.phone} expires ${item.expiration}`);
                });
                if (returned.length > 0)
                {    
                  this.subscriptionLabel =  returned.join(",");
                  
                }
              });

              this.navigate("../site/pages/DashboardPage", { replace: true });
        }

        
    }
    setLoggedIn(loggedin)
    {
        this.loggedIn = loggedin;
      
    }

    onFailure(obj)
    {

        this.setLoggedIn(false)
    }
}