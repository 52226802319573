import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import { BsShieldShaded, BsAlarm, BsPersonCheckFill, BsExclamationTriangle, BsArrowReturnRight, BsClockHistory} from "react-icons/bs";

import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;
const ShieldImage = BsShieldShaded;
const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Because we interact with our phones so regularly, an unexpected lag in that interaction could be the sign of a problem. Use these lags to inform when you should check on your loved ones." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: <BsShieldShaded/>,
      title: "Secure",
      description: "Authorize contacts to create subscriptions and receive notifications"
    },
    { imageSrc: <BsAlarm/>, 
      title: "Alerts",
      description: "Follow contacts and set thresholds for activity lags. Receive alerts based on these lags" },
    { imageSrc: <BsPersonCheckFill/>, 
      title: "Send Checkups",
      description: "Send checkups and receive well-being confirmations for peace of mind" },
    { imageSrc: <BsExclamationTriangle/>, 
      title: "Get Checkup Requests",
      description: "Receive checkup requests. These alerts include a location which expires for enhanced privacy" },
    { imageSrc: <BsArrowReturnRight/>, 
      title: "Flexible",
      description:"Get the information you want as your contacts use their phones like they normally would"},
    { imageSrc: <BsClockHistory/>, 
      title: "Quick",
      description:"Increase the frequency of quick, low contact interactions that fit into any schedule" }
  ];
/*<span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>*/

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span tw="text-primary-400" className="imageContainer">
                {card.imageSrc}
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Set thresholds for activity lags from your loved ones. Get alerted when to check-up on them based on those lags"}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
