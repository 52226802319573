import React, {useEffect} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { observer } from "mobx-react-lite";

import Header from "../components/headers/light.js";
import { useStore } from "../stores/root.store";
import { useNavigate } from "react-router";
import { Document, Page } from "react-pdf";
//import privacyPdf from "../docs/20220228 Vitalsleuth Privacy Policy.pdf";
import privacyPdf from "../docs/20220228 Privacy Policy.pdf";
import termsPdf from "../docs/20220228 Terms and Conditions.pdf";
import disclaimerPdf from "../docs/20220228 Disclaimer.pdf";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = tw.section` w-full`;
const Column = tw.div`relative text-center max-w-lg mx-auto lg:max-w-none lg:text-left mt-12`;

const ColumnHalf = tw.div`w-full`;
const Frame = tw.div`
 flex justify-center

`;

const Button = tw.button` bg-primary-500 tracking-wider font-semibold text-gray-100 border-white
`;

export default observer(({ roundedHeaderButton }) => {
  const root = useStore();
  let navigate = useNavigate();
  let docStore = root.docsStore;
  function onDocumentLoadSuccess({ numPages }) {
    let a = numPages;
  }
  function onLoadError(error) {
    let b = error;
  }

  function getDisableStyle(isDisabled) {
    return isDisabled ? tw`opacity-50` : "";
  }

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
      docStore.setCurrentWindowSize(windowWidth);
    }
    // Add event listener
    //window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () =>{} //window.removeEventListener("resize", handleResize);
  }, []);

  root.loginStore.setNavigate(navigate);
  const { innerWidth: pageWidth, innerHeight: pageHeight } = window;
  let pageNumber_Terms_previous_disabled = docStore.pageNumber_Terms <= 1;
  let pageNumber_Terms_next_disabled =
    docStore.pageNumber_Terms >= docStore.numPages_Terms;

  let pageNumber_Privacy_previous_disabled = docStore.pageNumber_Privacy <= 1;
  let pageNumber_Privacy_next_disabled =
    docStore.pageNumber_Privacy >= docStore.numPages_Privacy;

  let pageNumber_Disclaimer_previous_disabled =
    docStore.pageNumber_Disclaimer <= 1;
  let pageNumber_Disclaimer_next_disabled =
    docStore.pageNumber_Disclaimer >= docStore.numPages_Disclaimer;
  return (
    <AnimationRevealPage>
      <>
        <Header roundedHeaderButton={roundedHeaderButton} />
        <Container>
          <Frame>
            <Document
              file={privacyPdf}
              onLoadSuccess={docStore.setNumPages_Privacy}
              onLoadError={onLoadError}
              onSourceError={onLoadError}
              options={{ workerSrc: "/pdf.worker.js" }}

            >
              <Page width={docStore.currentWindowSize*.95} renderMode="svg" pageNumber={docStore.pageNumber_Privacy} />
            </Document>
          </Frame>
          <Frame>
            <p>
              Page {docStore.pageNumber_Privacy} of {docStore.numPages_Privacy}
            </p>
          </Frame>
          <Frame>
            <Button
              css={getDisableStyle(pageNumber_Privacy_previous_disabled)}
              type="button"
              disabled={pageNumber_Privacy_previous_disabled}
              onClick={docStore.previousPage_Privacy}
            >
              Previous
            </Button>
            <Button
              css={getDisableStyle(pageNumber_Privacy_next_disabled)}
              type="button"
              disabled={pageNumber_Privacy_next_disabled}
              onClick={docStore.nextPage_Privacy}
            >
              Next
            </Button>
          </Frame>
        </Container>
      </>
    </AnimationRevealPage>
  );
});
