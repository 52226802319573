

export default {
    apiKey: "AIzaSyB7z-fG5o3-fFI3KB1ztSr-T23_kp6RvxU",
  authDomain: "vitalsense-286619.firebaseapp.com",
  databaseURL: "https://vitalsense-286619-default-rtdb.firebaseio.com",
  projectId: "vitalsense-286619",
  appId: "1:158547013558:web:80a545bbf9012c61a7db17",
  measurementId: "G-HLFW7L7CD4"
    

}