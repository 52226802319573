
import {makeObservable, observable, action} from "mobx"

export default class LoginStore {

    numPages_Privacy  = 0 ; 
    pageNumber_Privacy = 1

    numPages_Disclaimer = 0; 
    pageNumber_Disclaimer = 1; 

    numPages_Terms = 0; 
    pageNumber_Terms = 1; 

    currentWindowSize = 0;

    constructor() {
    makeObservable(this, {

        setCurrentWindowSize: action.bound,
        //privacy
        numPages_Privacy: observable,
        pageNumber_Privacy: observable,
        currentWindowSize: observable,
        setNumPages_Privacy: action.bound,
        setPageNumber_Privacy: action.bound,
        previousPage_Privacy: action.bound,
        nextPage_Privacy: action.bound,

        //terms
        numPages_Terms: observable,
        pageNumber_Terms: observable,
        setNumPages_Terms: action.bound,
        setPageNumber_Terms: action.bound,
        previousPage_Terms: action.bound,
        nextPage_Terms: action.bound,     
        
        //disclaimer
        numPages_Disclaimer: observable,
        pageNumber_Disclaimer: observable,
        setNumPages_Disclaimer: action.bound,
        setPageNumber_Disclaimer: action.bound,
        previousPage_Disclaimer: action.bound,
        nextPage_Disclaimer: action.bound 
      })
    }

    setCurrentWindowSize(currSize)
    {
      this.currentWindowSize = currSize;
    }
    
    setNumPages_Privacy({numPages})
    {
        this.numPages_Privacy = numPages; 
    }

    setPageNumber_Privacy(pageNumber)
    {
        this.pageNumber_Privacy = pageNumber;
    }

     previousPage_Privacy() {
        return this.pageNumber_Privacy > 1 ? this.pageNumber_Privacy-- : this.pageNumber_Privacy;
      }
    
      nextPage_Privacy() {
        return this.pageNumber_Privacy < this.numPages_Privacy ? this.pageNumber_Privacy++ : this.pageNumber_Privacy;
      }

      // terms

      setNumPages_Terms({numPages})
    {
        this.numPages_Terms = numPages; 
    }

    setPageNumber_Terms(pageNumber)
    {
        this.pageNumber_Terms = pageNumber;
    }

     previousPage_Terms() {
        return this.pageNumber_Terms > 1 ? this.pageNumber_Terms-- : this.pageNumber_Terms;
      }
    
      nextPage_Terms() {
        return this.pageNumber_Terms < this.numPages_Terms ? this.pageNumber_Terms++ : this.pageNumber_Terms;
      }

      // disclaimer

      setNumPages_Disclaimer({numPages})
      {
          this.numPages_Disclaimer = numPages; 
      }
  
      setPageNumber_Disclaimer(pageNumber)
      {
          this.pageNumber_Disclaimer = pageNumber;
      }
  
       previousPage_Disclaimer() {
          return this.pageNumber_Disclaimer > 1 ? this.pageNumber_Disclaimer-- : this.pageNumber_Disclaimer;
        }
      
        nextPage_Disclaimer() {
          return this.pageNumber_Disclaimer < this.numPages_Disclaimer ? this.pageNumber_Disclaimer++ : this.pageNumber_Disclaimer;
        }
}