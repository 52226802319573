import React, {useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { observer } from "mobx-react-lite"
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import AnchorLink from 'react-anchor-link-smooth-scroll'
//import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";

import styled from "styled-components";
import Header from "../components/headers/light.js";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import { useStore } from "../stores/root.store";
import {useNavigate} from "react-router"
import ReCAPTCHA from 'react-google-recaptcha'
const Container = tw.div`relative`;
const Frame = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto  `;
const Column = tw.div`relative text-center max-w-lg mx-auto lg:max-w-none justify-center lg:items-center mt-10`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center `;
const ErrorText = tw.div`mt-1 text-center  text-red-600 font-bold`;

export const PrimaryButtonBase = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;


const PrimaryButton = tw(
  PrimaryButtonBase
)`px-12 py-2   rounded bg-secondary-200 text-gray-100 focus:shadow-outline focus:outline-none transition duration-300  inline-block tracking-wide text-center  tracking-normal`;


const SubmitButton = tw(
  PrimaryButton
)`mt-1 text-center`
const ActionButton = tw(
  AnchorLink
)`px-12 py-2 mt-1  rounded bg-secondary-200 text-gray-100 focus:shadow-outline focus:outline-none transition duration-300  inline-block tracking-wide text-center  tracking-normal`;

//const PrimaryButton = tw(ActionButton)``;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Spacer = tw.div`mb-2`
const Div = tw.div``
const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;
const responseGoogle = (response) => {
  console.log(response);
}

export default  observer(({ roundedHeaderButton }) => {
  
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const root = useStore();
 let navigate = useNavigate();
 const recaptchaRef = React.createRef();
  //const [store] = useState(() => root.loginStore)
  root.loginStore.setNavigate(navigate)
  let props = {height: 30,
    width: 300,
    color: 'black',
    border: false,
    type: 'sign-in',
    border_radius: 15,
    scale: 1,
    locale: 'en_US'}



  return (

    <AnimationRevealPage>
      <>
      {root.loginStore.confirmedNotBot === false }
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <Frame> 
          <Column>
          {true &&

             <form onSubmit={async()=>{await root.loginStore.onFormSubmit(recaptchaRef)}} >

              <ReCAPTCHA
              sitekey="6LcWnH8fAAAAAEra4hsCyZ4VF0LUBovaIA9ipCTp"
                
                ref={recaptchaRef}
                onChange={
                () =>
                {
                 root.loginStore.setConfirmedNotBot(true);
                }
                }
                onExpired={
                  () => {
                    root.loginStore.setConfirmedNotBot(false)
                  }
                }
              />
                           </form>   

              }
          {root.loginStore.confirmedNotBot && !root.loginStore.performFirebaseLogin &&
          <GoogleLogin width="300"
              onSuccess={credentialResponse => {
                console.log('credential was')
                console.log(credentialResponse);
                root.loginStore.onLogin(credentialResponse.credential, undefined)
              }}
              onError={() => {
                console.log('Login Failed');
              }}
          />
          
         
         /*    <GoogleLogin
            clientId="158547013558-mklabdjo3ab87r38lfnlqvfc19kjlgdl.apps.googleusercontent.com"
            disabled={false}
            buttonText="Log in with Google"
            onSuccess={root.loginStore.onLogin}
            onFailure={(msg) =>root.loginStore.onFailure(root.loginStore)}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
              />*/
              
              }
              <Spacer/>
              {root.loginStore.confirmedNotBot && !root.loginStore.performFirebaseLogin &&

                      <AppleSignin
                      
                      authOptions={{
                        clientId:"com.vitalsleuth.web" ,
                        scope: 'email name',
                        redirectURI:"https://vitalsense-48ae2.firebaseapp.com",
                        state: 'state',
                        nonce: 'nonce' 

                      }}
                      
                      onSuccess={(response) => console.log(response)}
                      onError={(error) => console.error(error)} // default = undefined

                      />

              }
                            <Div/>

              {root.loginStore.confirmedNotBot && !root.loginStore.performFirebaseLogin &&

                <PrimaryButton  onClick={() => {
                  root.loginStore.setPerformFirebaseLogin(true)}}>Sign in with account</PrimaryButton>
              }
{root.loginStore.confirmedNotBot && root.loginStore.performFirebaseLogin &&
      <Div>
          <Input type="email" placeholder="Email Address" onInput={e => root.loginStore.setEmail(e.target.value)} value={root.loginStore.emailAddress}/>
        
        <Div/>
          <Input type="password" placeholder="Password" onInput={e => root.loginStore.setPassword(e.target.value)} value={root.loginStore.password} />
        <Div/>

        <SubmitButton onClick={async () => {
            console.info('perform firebase login ' + root.loginStore.emailAddress)
            await root.loginStore.firebaseLogin(root.loginStore.emailAddress, root.loginStore.password)
        }}>Submit</SubmitButton>
        </Div>}

          <ErrorText>{root.loginStore.errorMessage}</ErrorText>    
          </Column>
        </Frame>
      </Container>
      </>

    </AnimationRevealPage>
  );
})
